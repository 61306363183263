export const API_ROUTES = {
  authCallback: "/auth/callback",
  job: "/api/job",
  employer: "/api/employer",
  company: "/api/company",
  application: "/api/application",
  applicationCreate: "/api/application/create",
  applicationCreateMany: "/api/application/create-many",
  candidate: "/api/candidate",
  reportCandidate: "/api/candidate/report",
  notification: "/api/notification",
  chat: "/api/chat",
  chatMessage: "/api/chat/message",
  chatMarkAllAsRead: "/api/chat/mark-all-as-read",
  jobRepost: "/api/job/repost",
  jobArchive: "/api/job/archive",
  team: "/api/team",
  teamInvite: "/api/team/invite",
  teamUser: "/api/team/user",
  payment: "/api/payment",
  activityLog: "/api/activity_log",
  contact: "/api/contact",
  form: "/api/form",
  verifyPassword: "/api/auth/verify-password",
  referenceVerification: "/api/reference-verification",
  checkUserExists: "/api/auth/check-user-exists",
  checkUserType: "/api/auth/check-user-type",
  geminiFromText: "/api/gemini/from-text",
};
